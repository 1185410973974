<template>
  <div class="elite-tabs-wrapper-content">
    <div class="title-header">
      <div class="row">
        <div class="col-6">
          <h3>{{ supplier.company_name }}</h3>
        </div>

        <div class="col-6 text-right">
          <base-button
            class="btn-outline-danger"
            icon
            size="sm"
            v-if="$currentUserCan($permissions.PERM_DELETE_SUPPLIERS)"
            @click="deleteSupplier"
          >
            <span class="btn-inner--icon">
              <i class="far fa-trash-alt"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.DELETE") }}
            </span>
          </base-button>

          <base-button
            class="elite-button add"
            icon
            size="sm"
            v-if="$currentUserCan($permissions.PERM_EDIT_SUPPLIERS)"
            @click="editSupplier"
          >
            <span class="btn-inner--icon">
              <i class="far fa-edit"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.EDIT") }}
            </span>
          </base-button>
        </div>
      </div>
    </div>

    <div class="all-infos">
      <img
        v-if="product.picture"
        class="all-infos-image"
        :src="product.picture"
        alt="logo"
      />

      <img
        v-else
        class="all-infos-image"
        src="/img/placeholder.jpg"
        alt="logo"
      />

      <div class="all-infos-text">
        <dl class="row">
          <dt>
            {{ $t("COMMON.TAGS") }}
          </dt>
          <dd>
            <tags :tags="supplier.tags" @tagsUpdated="supplierUpdated" />
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.FISCAL_NUMBER") }}</dt>
          <dd>
            {{ supplier.fiscal_number }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.ADDRESS") }}</dt>
          <dd>
            {{ supplier.address }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.EMAIL") }}</dt>
          <dd>
            {{ supplier.email }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.PHONE") }}</dt>
          <dd>
            {{ supplier.phone }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.COUNTRY") }}</dt>
          <dd>
            {{ supplier.country }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.STATE") }}</dt>
          <dd>
            {{ supplier.state }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.CITY") }}</dt>
          <dd>
            {{ supplier.city }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.ZIPCODE") }}</dt>
          <dd>
            {{ supplier.zipcode }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.ADDRESS") }}</dt>
          <dd>
            {{ supplier.address }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.LOCATIONS") }}</dt>
          <dd>
            <locations :locations="supplier.allowedLocations" />
          </dd>
        </dl>

        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
        >
          <dt>{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd>
            <organization :organization="supplier.organization" />
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd>
            {{ supplier.created_at | moment("LLLL") }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd>
            {{ supplier.updated_at | moment("LLLL") }}
          </dd>
        </dl>
      </div>

      <div class="mb-2 mt-4">
        <p class="labe-red">
          {{ $t("COMMON.BILLING_ADDRESS") }}
        </p>
      </div>

      <billing-informations-view
        :billingInformations="supplier"
        :type="supplier.billing_entity_type"
      />
    </div>
  </div>
</template>

<script>
import models from "@/constants/models";
import Tags from "@/components/Tags.vue";
import BillingInformationsView from "@/components/BillingInformationsView.vue";

export default {
  name: "supplier-view-global",

  components: { Tags, BillingInformationsView },

  props: ["supplier"],

  data() {
    return {};
  },

  computed: {},

  methods: {
    supplierUpdated() {
      this.$emit("supplierUpdated", true);
    },
  },

  watch: {
    supplier(supplier) {},
  },
};
</script>
