<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!supplier">
      <span class="loader"></span>
    </span>
    <div v-if="supplier" class="elite-tabs-wrapper">
      <tabs
        fill
        class="flex-column flex-md-row"
        tabNavWrapperClasses="nav-wrapper"
        tabNavClasses="nav elite-tabs"
        value="global"
      >
        <tab-pane title="global" id="1" :active="true">
          <span slot="title">
            <i class="ni ni-cloud-upload-96"></i>
            {{ $t("COMMON.GLOBAL") }}
          </span>
          <supplier-view-global
            :supplier="supplier"
            @onEditSupplier="editSupplier"
            @onDeleteSupplier="deleteSupplier"
          />
        </tab-pane>

        <tab-pane
          title="contacts"
          id="2"
          :active="true"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_CONTACTS)"
        >
          <span slot="title">
            <i class="ni ni-circle-08"></i>
            {{ $t("COMMON.CONTACTS") }}
          </span>
          <supplier-view-contacts :supplier="supplier" />
        </tab-pane>

        <tab-pane
          title="files"
          id="998"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_FILES)"
        >
          <span slot="title">
            <i class="ni ni-folder-17"></i>
            {{ $t("COMMON.FILES") }}
          </span>
          <div class="elite-tabs-wrapper-content">
            <list-file-component :object="supplier" />
          </div>
        </tab-pane>

        <tab-pane
          title="logs"
          id="999"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
        >
          <span slot="title">
            <i class="fa fa-file"></i>
            {{ $t("COMMON.LOGS") }}
          </span>
          <supplier-view-logs :supplier="supplier" />
        </tab-pane>
      </tabs>
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import { Tabs, TabPane } from "@/components";
import ListFileComponent from "@/components/File/ListFileComponent.vue";
import SupplierViewGlobal from "../partials/SupplierViewGlobal.vue";
import SupplierViewLogs from "../partials/SupplierViewLogs.vue";
import SupplierViewContacts from "../partials/SupplierViewContacts.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    SupplierViewGlobal,
    SupplierViewLogs,
    SupplierViewContacts,
    ListFileComponent,
  },

  mixins: [],

  props: {
    supplierId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      supplier: null,
    };
  },

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        await this.$store.dispatch("suppliers/get", this.supplierId);
        this.supplier = this.$store.getters["suppliers/supplier"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
    editSupplier(supplier) {
      this.$emit("onEditSupplier", supplier, true);
    },
    deleteSupplier(supplier) {
      this.$emit("onDeleteSupplier", supplier);
    },
  },
};
</script>
